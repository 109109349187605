import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class SimService extends DefaultService {

  constructor() {
    super('notarias');
  }

  getPagoPredial(clave_catastral: string): Promise<any>{
    return API.get(`notarias/getPagoPredial/${clave_catastral}`);
  }

  getInfoPredio(clave_catastral: string): Promise<any>{
    return API.get(`/getInfoPredio/${clave_catastral}`);
  }

  getTipoOperaciones(): Promise<any>{
    return API.get(`/getTipoOperaciones/`);
  }

  pagosPorAnio(data: any): Promise<any>{
    return API.post(`/pagosPorAnio/`,data);
  }

  getValuacion(data: any): Promise<any>{
    return API.post(`/postPredioValuacion`,data);
  }

}

export default new SimService();